<template>
<div>

    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadísticas</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA LOS INFORMES DE ASEGURAMIENTO DE CALIDAD
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class=" my-2 icon-custom">
                            <i style="inline-size: auto" class="ico fas fa-thumbs-up fa-3x"></i> <br>
                            <span class="lg-numero">{{listaAseguramiento.length}}</span>
                            <br>
                            <span class="text-muted">Aseguramientos de calidad registrados</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog fa-md"></i> <span class="h5"> Gestión de Aseguramientos de calidad</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col v-if="checkPermissions('007-9001-ASE','c')==1" cols="4" class="my-2">
                            <b-button block size="md" variant="custom" :to="{name: 'Gestión aseguramiento de calidad'}">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br>Nuevo informe
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5"> Aseguramientos de calidad registrados</span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col lg="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col lg="7">
                        </b-col>
                        <b-col lg="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search  my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table :items="listaAseguramiento" :fields="campoAseguramiento" bordered :per-page="porPagina" :current-page="currentPage" @filtered="onFiltered" :filter="filter" :filter-included-fields="filterOn" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template #cell(opciones)="param">
                                    <b-button v-if="checkPermissions('007-9001-ASE','u')==1" :to="{name: 'Gestión aseguramiento de calidad', params: {id: param.item.idAseguramientoCalidad}}" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                        <i class="fas fa-search  my-0 mx-0"></i>
                                    </b-button>
                                    <b-button v-if="checkPermissions('007-9001-ASE','d')==1" @click="eliminarAseguramiento(param)" size="sm" class="mr-1 mb-1" variant="danger" v-c-tooltip="'Eliminar'">
                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                    </b-button>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    components: {

    },
    data() {
        return {

            totalRows: 0,
            currentPage: 1,
            filter: null,
            filterOn: [],
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            campoAseguramiento: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                }, {
                    key: "anio",
                    label: "Ejercicio / Año",
                    class: "text-center",
                },
                {
                    key: "proyecto",
                    label: "Proyecto / N° Lote",
                    class: "text-center",
                },
                {
                    key: "requisitosPS",
                    label: "Requisitos para los productos y servicios",
                    class: "text-center",
                },
                {
                    key: "disenoPS",
                    label: "Diseño y desarrollo de los productos y servicios",
                    class: "text-center",
                },
                {
                    key: "produccionPS",
                    label: "Producción y provisión del servicio",
                    class: "text-center",
                },
                {
                    key: "liberacionPS",
                    label: "Liberación de los productos y servicios",
                    class: "text-center",
                },
                {
                    key: "controlSNC",
                    label: "Control de las salidas no conformes",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center"
                }
            ],
            listaAseguramiento: [],
            datosSession: {
                idCliente: ''
            },
        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        listarAseguramientoCalidad() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-aseguramiento-calidad", {
                    params: {
                        idCliente: me.datosSession.idCliente,
                        ejercicio: JSON.parse(localStorage.settings).yExcs
                    }
                })
                .then(function (response) {
                    me.listaAseguramiento = response.data;
                    me.totalRows = me.listaAseguramiento.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        eliminarAseguramiento(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el informe de la norma?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-aseguramiento-calidad", {
                            idAseguramientoCalidad: param.item.idAseguramientoCalidad,
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`,
                            }
                        })
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarAseguramientoCalidad();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }

    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSession.idCliente = user.uidClient;
            this.listarAseguramientoCalidad();
        }
    }

}
</script>
